import cx from "classnames";
import kebabCase from "lodash/kebabCase";
import React from "react";

import { HomeFieldsFragment, Scalars } from "../../graphql-types";
import { truncateMetaDescription } from "../utilities/helpers";
import AnalyticsDataLayer from "./AnalyticsDataLayer";
import FacebookDomainVerification from "./FacebookDomainVerification";
import Favicon from "./Favicon";
import LazyLoader from "./LazyLoader";

import "brand-css";
import "../css/layout.css";
import "./Fonts";

export const LazyAdobeAnalytics = () => import("./AdobeAnalytics");
export const LazyCookieConsentAsync = () => import("./CookieConsentAsync");
export const LazyEIETracking = () => import("./EIETracking");
export const LazyMyTargetAnalytics = () => import("./MyTargetAnalytics");
export const LazyGlobalFacebookTag = () => import("./GlobalFacebookTag");
export const LazyCustomPixels = () => import("./CustomPixels");
export const LazyHotJar = () => import("./HotJar");
export const LazyTikTokAnalytics = () => import("./TikTokAnalytics");

type Props = {
  absolutePath: string;
  pageTitle?: string;
  metaDescription: string;
  pageType?: string;
  testId?: string;
  shareImgSrc?: string;
  alternatives?: {
    href: string;
    market: string;
  }[];
  home: HomeFieldsFragment;
  dmpAttributes?: Scalars["JSON"];
  channel?: string;
  headClassNames?: string;
  id?: string;
};

export default function Head({
  absolutePath,
  alternatives,
  home,
  metaDescription,
  pageTitle,
  pageType,
  testId,
  shareImgSrc,
  dmpAttributes,
  channel,
  headClassNames,
  id,
}: Props) {
  const { brand, domain } = home;
  const truncatedMetaDescription = truncateMetaDescription(metaDescription);
  const brandKebabCase = kebabCase(domain.name + domain.path);
  let title = `${pageTitle || brand.name}`;
  if (pageType !== "home") {
    title += ` | ${brand.name}`;
  }
  const lang = domain.lang.includes("-")
    ? domain.lang
    : `${domain.lang}-${domain.countryCode}`;

  return (
    <>
      <html
        data-testid={testId}
        lang={lang}
        className={cx(headClassNames, brandKebabCase, pageType, {
          [domain.path.slice(1)]: domain.path !== "/",
        })}
        data-ctf-id={id ?? ""} // used internally for prod<>ctf bookmarklet
      />
      <title>{title}</title>
      <meta name="description" content={truncatedMetaDescription} />
      <meta httpEquiv="content-language" content={domain.lang} />
      <meta property="fb:pages" content="350316462033370" />
      <meta property="og:url" content={absolutePath} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:site_name" content={brand.name} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={truncatedMetaDescription} />
      {shareImgSrc && <meta property="og:image" content={shareImgSrc} />}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={absolutePath} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={truncatedMetaDescription} />
      {shareImgSrc && <meta name="twitter:image" content={shareImgSrc} />}
      {alternatives &&
        alternatives.map(({ href, market }) => (
          <link
            key={`${market}${href}`}
            rel="alternate"
            hrefLang={market}
            href={href}
          />
        ))}
      <link rel="canonical" href={absolutePath} />

      <Favicon brand={brand} />
      <FacebookDomainVerification />

      {domain.hasVersatag && (
        <script>{`
          (function(e, s, t) { t = t || {}, t.id = e, versaTagObj = { $: [], onready: function(e) { this.$.push(e) } }; var n = s.getElementsByTagName("script")[0], r = s.createElement("script"); r.options = t, r.async = !0, r.src = "https://secure-ds.serving-sys.com/SemiCachedScripts/ebOneTag.js?id=" + e, r.options = t, n.parentNode.insertBefore(r, n) })("1073747554", document, { dataLayer:"sz_datalayer" });
`}</script>
      )}

      {domain.adobeConfig && (
        <LazyLoader
          dynamicImport={LazyAdobeAnalytics}
          props={{ adobeConfig: domain.adobeConfig }}
        />
      )}
      {domain.hasEIETracking && (
        <LazyLoader dynamicImport={LazyEIETracking} props={{}} />
      )}
      {domain.myTargetAnalyticsID && (
        <LazyLoader
          dynamicImport={LazyMyTargetAnalytics}
          props={{ id: domain.myTargetAnalyticsID }}
        />
      )}
      <LazyLoader dynamicImport={LazyCustomPixels} props={{ absolutePath }} />
      <LazyLoader
        dynamicImport={LazyCookieConsentAsync}
        props={{ oneTrustId: domain.oneTrustId }}
      />
      {domain.hotJarID && (
        <LazyLoader
          dynamicImport={LazyHotJar}
          props={{ absolutePath, hotJarID: domain.hotJarID }}
        />
      )}
      <LazyLoader dynamicImport={LazyGlobalFacebookTag} props={{}} />
      {domain.tiktokAnalyticsID && (
        <LazyLoader
          dynamicImport={LazyTikTokAnalytics}
          props={{
            brand,
            channel,
            pageTitle,
            pageType,
            tiktokAnalyticsID: domain.tiktokAnalyticsID,
          }}
        />
      )}
      <AnalyticsDataLayer
        {...{
          absolutePath,
          title: pageTitle || "",
          dmpAttributes,
          pageType,
          domain,
          brand,
          channel,
        }}
      />
    </>
  );
}
