import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import React, { ReactNode } from "react";

import { HomeFieldsFragment } from "../../graphql-types";
import Footer from "./Footer/Footer";
import Header from "./Header";
import JSONLDBrand from "./jsonld/JSONLDBrand";
import JSONLDTag from "./jsonld/JSONLDTag";
import JSONLDTagOrganization from "./jsonld/JSONLDTagOrganization";
import JSONLDWebsite from "./jsonld/JSONLDWebsite";
import Main from "./Main";
import OneTrustBeacons from "./OneTrustBeacons";
import OneTrustShowSettings from "./OneTrustShowSettings";
import { SkipNav } from "./SkipNav";

import { MyTargetAnalyticsBody } from "./MyTargetAnalytics";

import "brand-css";
import "../css/layout.css";
import "./Fonts";

export const STATIC_QUERY = graphql`
  query LayoutQuery {
    homeLink: website {
      path
    }
  }
`;

type Props = {
  absolutePath: string;
  className?: string;
  pageTitle?: string;
  metaDescription: string;
  children?: ReactNode;
  pageType?: string;
  home: HomeFieldsFragment;
  fullWidth?: boolean;
  hideShell?: boolean;
};

export default function Layout({
  absolutePath,
  children,
  className,
  home,
  pageTitle,
  fullWidth = false,
  hideShell = false,
}: Props) {
  const { homeLink } = useStaticQuery(STATIC_QUERY);
  const { brand, domain, legals } = home;

  return (
    <>
      {domain.myTargetAnalyticsID && (
        <MyTargetAnalyticsBody id={domain.myTargetAnalyticsID} />
      )}
      <SkipNav contentId="main-content" />
      <OneTrustBeacons />
      <JSONLDWebsite name={brand.name} absolutePath={absolutePath} />
      <JSONLDTag
        json={{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": `${absolutePath}#webpage`,
          name: pageTitle,
          url: absolutePath,
        }}
      />
      {brand?.logo?.gatsbyImageData && (
        <JSONLDBrand
          absolutePath={absolutePath}
          description={brand.description}
          logo={getSrc(brand.logo.gatsbyImageData) as string}
          name={brand.name}
          sameAs={(legals.socialChannels || []).map((el) => el?.url || "")}
        />
      )}
      <JSONLDTagOrganization
        logo={brand.logo.gatsbyImageData}
        sameAs={(legals.socialChannels || []).map((el) => el?.url || "")}
      />
      {!hideShell && (
        <Header
          className="items-center max-w-container mx-auto lg:flex lg:items-stretch"
          homePath={
            absolutePath.startsWith("https://www.unilever.digital")
              ? absolutePath
              : homeLink.path
          }
          brandLogoDescription={brand.logo.description || ""}
          brandLogoImage={brand.logo.gatsbyImageData}
          absolutePath={absolutePath}
        />
      )}
      <div
        className={cx("relative mx-auto body-wrapper", {
          "max-w-container": !fullWidth,
        })}
      >
        <Main className={className}>{children}</Main>
        {home && !hideShell && <Footer home={home} />}
        <OneTrustShowSettings {...domain} />
      </div>
    </>
  );
}
